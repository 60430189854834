/* .ant-menu.ant-menu-dark {
  background: #333333 !important;
}

.ant-layout-header {
  background: #333333 !important;
}
.ant-layout-sider {
  background: #333333 !important;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item {
  background: #333333 !important;
} */

.user-btn {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
}

.accre_logo {
  width: 300px;
  height: 100%;
  object-fit: cover;
  margin-left: -70px;
}
